<template>
  <div class="dutyInfos-managet-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false">
      <ml-form labelWidth="70px" style="width: 500px" :model="companyAndProjectDForm">
        <ml-cascader
          :props="{ label: 'label', value: 'id'}"
          label="值班日志"
          :options="companyAndProjectDadta"
          placeholder="请选择阿米巴、项目"
          v-model="companyAndProjectDForm['val']"
          :style="{ marginBottom: 0 }"
          @change="selectCompanyAndProject"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip
      content=""
      :showHeader="false"
      class="default-tip"
      :style="`margin-bottom: 0; border: 0; min-height: ${tabberHeight(175)}px`"
    >
      <div class="duty-box" :style="{ height: `${tabberHeight(206)}px` }">
        <div class="duty-box-left">
          <div style="padding: 20px 50px 0 40px">
            <!-- 日历 -->
            <div id="zxVueCalendar" style="width: 400px"></div>
          </div>
          <el-scrollbar :height="`${tabberHeight(575)}px`">
            <class-item isLog v-model="classData" @click-log="clickLog" />
          </el-scrollbar>
        </div>
        <div class="duty-box-right">
          <!-- 右侧标题 -->
          <ml-tip
            :showHeader="false"
            :style="{ borderBottom: 0 }"
            :bodyStyle="{ padding: '10px 20px' }"
          >
            <div class="duty-box-right-tip">
              <div class="duty-box-right-tip-title">
                查看值班日志
                {{ year }}-{{ smallTenStr(month) }}-{{ smallTenStr(day) }}
              </div>

              <div>
                <!-- <el-button type="text" @click="onEdit">编辑</el-button> -->
              </div>
            </div>
          </ml-tip>
          <duty-table
            v-model="classData"
            v-model:isPaveMonth="isPaveMonth"
            :year="year"
            :month="month"
            isLog
            cellClassHame="duty-log-table"
          />
        </div>
      </div>
    </ml-tip>
    <sub-log-dlg ref="sldRef" :config="slgData" :item="logItem" @click-submit="clickSubmitLog" />
  </div>
</template>

<script>
import { nextTick, onUnmounted, reactive, ref, watch } from 'vue'
import { tabberHeight, getTabberData, smallTenStr } from '@/utils'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ZxVueCalendar from 'zx-calendar'

import DutyTable from '../dutyInfosManage/components/table.vue'
import ClassItem from '../dutyInfosManage/components/classItem.vue'
import SubLogDlg from './components/submitLog.vue'

export default {
  name: 'DutyLog',
  components: {
    DutyTable,
    ClassItem,
    SubLogDlg
  },
  setup() {
    const { commit, dispatch } = useStore()
    // 获取地址栏参数
    const route = useRoute()
    const query = route.query
    // tip 提示 阿米巴 项目
    const tipContent = ref('')
    // 处理当前时间，年月日
    const nDate = new Date()
    const year = ref(query.year || nDate.getFullYear())
    const month = ref(query.month || nDate.getMonth() + 1)
    const day = ref(query.day || nDate.getDate())
    const dutyId = ref('')
    // 日历对象
    const zxVueCalendar = ref()

    const recordId = ref('')
    const isHhliday = ref([])
    // 值班ID
    const dutyCalendars = ref({})

    // 班次信息
    const classData = ref([])

    const slgData = reactive({
      companyId: '',
      projectId: ''
    })

    // 获取值班日历集合
    const getDutyCalendars = async () => {
      let nData = []
      if (dutyId.value) {
        const { data = [] } = await dispatch('fetchGetDutyCalendars', {
          dutyId: dutyId.value
          // year: year.value,
          // month: month.value
        })
        nData = data
      }
      isHhliday.value = []
      // 收集值班日与值班对应的ID
      if (nData.length > 0) {
        nData.map(item => {
          isHhliday.value.push(item.everyDate)
          dutyCalendars.value[item.everyDate] = item.recordId
          const m = smallTenStr(month.value)
          const d = smallTenStr(day.value)
          if (item.everyDate === `${year.value}-${m}-${d}`) {
            recordId.value = item.recordId
            getDutyTimeItems(item.recordId)
          } else {
            getDutyTimeItems()
          }
        })
      } else {
        classData.value = []
      }
      // 创建日历
      if (zxVueCalendar.value) {
        const m = smallTenStr(month.value)
        const d = smallTenStr(day.value)
        zxVueCalendar.value.setDate(new Date(`${year.value}-${m}-${d}`))
      } else {
        const ZxVueCalendarOption = {
          el: '#zxVueCalendar',
          showHoliday: true,
          // 格式化日历项
          itemFormatter: e => {
            if (e.fullText) {
              // 查看日历项是否是激活日期
              const fullText = e.fullText.replaceAll('/', '-')
              // 如果日期正常并存在值班就显示点
              e.holiday = isHhliday.value.includes(fullText)
            }
            return e
          }
        }
        if (query.year && query.month && query.day) {
          ZxVueCalendarOption.defaultDate = `${query.year}-${query.month}-${query.day}`
        }
        zxVueCalendar.value = new ZxVueCalendar(ZxVueCalendarOption)
      }
    }
    // 查询班次信息集合
    const getDutyTimeItems = async calRecordId => {
      if (calRecordId) {
        const { data = [] } = await dispatch('fetchGetDutyTimeItems', { calRecordId })

        if (data.length > 0) {
          classData.value = data
        } else {
          classData.value = []
        }
      } else {
        classData.value = []
      }
    }

    // 加载值班日历后创建日历并监听日历事件
    nextTick(async () => {
      await getDutyInfos()
      await getDutyCalendars()
      if (zxVueCalendar.value) {
        zxVueCalendar.value.on('change', e => {
          // const fullText = e[0].fullText.replaceAll('/', '-')
          const [y, M, d] = e[0].fullText.split('/')
          year.value = y
          month.value = M
          day.value = d
          recordId.value = dutyCalendars.value[`${y}-${M}-${d}`]
          if (recordId.value) {
            getDutyTimeItems(recordId.value)
          } else {
            classData.value = []
          }
        })
      }
    })

    const isPaveMonth = ref(true)
    watch(
      () => isPaveMonth.value,
      newvalue => {
        if (newvalue) {
          getDutyCalendars()
        }
      }
    )

    // 打开提交日志对话框
    const sldRef = ref()
    const logItem = ref({})
    const clickLog = item => {
      logItem.value = { ...item }
      sldRef.value.submitLogDlgRef.showDialog = true
    }

    const clickSubmitLog = submitLogFrom => {
      const { claId, classId, userId } = logItem.value
      const calRecordId = recordId.value
      const params = { ...submitLogFrom, calRecordId, claId, classId, userId }
      dispatch('fetchDutyCalendarSummary', params).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          getDutyTimeItems(calRecordId)
          sldRef.value.submitLogDlgRef.showDialog = false
        }
      })
    }

    // 获取值班列表
    const classDatalist = ref([])
    const companyAndProjectDadta = ref([])
    const companyAndProjectDForm = reactive({
      val: []
    })
    const getDutyInfos = async () => {
      const { data = [] } = await getTabberData(dispatch, 'fetchGetDutySelections')
      const nOption = []
      classDatalist.value = []
      if (data && Object.keys(data).length > 0) {
        let nData = []
        Object.keys(data).map(item => {
          nData = [...nData, ...data[item]]
        })
        classDatalist.value = nData
        nData.map((item, index) => {
          if (index === 0) {
            companyAndProjectDForm.val = [item.companyId, item.projectId]
            slgData.companyId = item.companyId
            slgData.projectId = item.projectId
            dutyId.value = item.dutyId
          }
          const companyItem = nOption.find(f => f.id === item.companyId)
          if (companyItem) {
            nOption.map(v => {
              if (v.id === item.companyId) {
                v.children.push({
                  id: item.projectId,
                  label: item.projectName
                })
              }
              return v
            })
          } else {
            nOption.push({
              id: item.companyId,
              label: item.companyName,
              children: [
                {
                  id: item.projectId,
                  label: item.projectName
                }
              ]
            })
          }
        })
      }
      companyAndProjectDadta.value = [...nOption]
    }

    // 选择阿米巴和项目
    const selectCompanyAndProject = () => {
      // console.log(companyAndProjectDadta.value)
      const [companyId, projectId] = companyAndProjectDForm.val
      slgData.companyId = companyId
      slgData.projectId = projectId
      const nClassDataItem = classDatalist.value.find(item => item.projectId === projectId)
      if (nClassDataItem && nClassDataItem.dutyId) {
        dutyId.value = nClassDataItem.dutyId
        getDutyCalendars()
      } else {
        classData.value = []
      }
    }
    onUnmounted(() => {
      zxVueCalendar.value.destroy()
    })

    return {
      tabberHeight,
      tipContent,
      year,
      month,
      day,
      classData,
      isPaveMonth,
      clickLog,
      slgData,
      sldRef,
      logItem,
      clickSubmitLog,
      companyAndProjectDadta,
      companyAndProjectDForm,
      selectCompanyAndProject,
      smallTenStr
    }
  }
}
</script>

<style lang="scss">
.dutyInfos-managet-action {
  @extend %params-global;
}
.duty-box {
  display: flex;
  height: 100%;
  &-left {
    width: 480px;
    border-right: 1px solid #d8d8d8;
  }
  &-right {
    flex: 1;
    &-tip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        font-weight: bold;
      }
    }
  }
}

// 日历样式
.zx-calendar .zx-calendar-header-wrapper .__l button:before,
.zx-calendar .zx-calendar-header-wrapper .__l button:after,
.zx-calendar .zx-calendar-header-wrapper .__r button:before,
.zx-calendar .zx-calendar-header-wrapper .__r button:after {
  border-top: 1px solid #409eff;
  border-right: 1px solid #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__holiday {
  background: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-current .__inner .__text {
  background: #fff;
  color: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-selected .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-temp .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-first-last .__inner .__text {
  background: #409eff;
  color: #fff;
}
.duty-log-table {
  .cell {
    padding: 0 !important;
  }
}
</style>
