<template>
  <!-- 提交日志提示框 -->
  <ml-dialog
    width="600px"
    ref="submitLogDlgRef"
    title="提交日志"
    @click-submit="submitLogDlgSubmit"
    @before-close="closeFn"
    @click-close="closeFn"
    @open="closeFn"
  >
    <template #body>
      <ml-form
        :style="{ width: '560px' }"
        :model="submitLogFrom"
        ref="submitLogFromRef"
        :rules="submitLogFromRules"
      >
        <!-- 用户 -->
        <ml-input
          prop="summary"
          placeholder="请输入提交内容"
          label=""
          type="textarea"
          :rows="8"
          :maxlength="500"
          v-model="submitLogFrom.summary"
        />

        <el-form-item>
          <ml-list fileName="fileName" :list="submitLogFrom.attachVos" />
        </el-form-item>

        <ml-button :showCancel="false" submitText="上传附件" @click-submit="showUploadDia" />
      </ml-form>
    </template>
  </ml-dialog>
  <!-- 上传附件-资源管理器 -->
  <c-upload-dlg
    v-if="uploadConfig.length > 0"
    ref="cUploadDlgRef"
    v-model="uploadData"
    :config="uploadConfig"
  />
</template>

<script>
import { reactive, ref, nextTick, watch, onMounted } from 'vue'
export default {
  name: 'SubLogDlg',
  props: {
    item: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const submitLogDlgRef = ref()
    const submitLogFromRef = ref()
    const submitLogFrom = reactive({
      summary: '',
      attachVos: []
    })
    const submitLogFromRules = {
      summary: [{ required: true, message: '请输入内容 ', trigger: 'blur' }]
    }
    const submitLogDlgSubmit = () => {
      submitLogFromRef.value.CustomFormRef.validate()
        .then(() => {
          context.emit('click-submit', submitLogFrom)
        })
        .catch(() => {})
    }

    /* 2021-12-15 新版上传文件管理器 wml ******************/
    // 上传资源管理器属性
    const cUploadDlgRef = ref()
    const uploadData = ref([])
    const uploadConfig = ref([])

    watch(
      () => uploadData.value,
      newvalue => {
        submitLogFrom.attachVos = [...submitLogFrom.attachVos, ...newvalue]
      }
    )
    // 打开对话框
    const showUploadDia = () => {
      const { companyId, projectId } = props.config
      uploadData.value = []
      uploadConfig.value = [companyId, projectId]
      nextTick(() => {
        cUploadDlgRef.value.uploadDialogRef.showDialog = true
      })
    }
    /* 2021-12-15 新版上传文件管理器 wml ******************/
    const closeFn = () => {
      submitLogFrom.summary = ''
      submitLogFrom.attachVos = []
    }
    onMounted(() => {
      submitLogFrom.summary = ''
      submitLogFrom.attachVos = []
    })
    return {
      submitLogDlgRef,
      submitLogFromRef,
      submitLogFrom,
      submitLogDlgSubmit,
      showUploadDia,
      uploadConfig,
      cUploadDlgRef,
      uploadData,
      submitLogFromRules,
      closeFn
    }
  }
}
</script>

<style></style>
